.AnalysisDescription {
  font-family: "Lato", sans-serif;
  padding: 30px 25px;
  color: #001529;

  &__PageTitle.ant-typography {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 47px;
    padding-bottom: 24px;
    margin: 0;
  }

  &__PageSubtitle.ant-typography {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 159.69%;
    padding-bottom: 20px;
    margin: 0;
  }

  &__SubtitleRow {
    margin-bottom: 20px;
  }

  &__FormLabel.ant-typography {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    color: #868686;
  }
}