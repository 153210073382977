.TrackerSidebar {
  border-right: 0;

  &__GroupItem {
    padding-top: 16px;

    div {
      font-size: 1rem;
      text-transform: uppercase;
      margin-left: 9px;
    }

    .ant-menu-submenu-title {
      padding-left: 16px !important;
    }

    .ant-menu-submenu-title:active,
    .ant-menu-submenu-title:hover {
      background: transparent;
      color: #868686;
    }

    .ant-menu-item-selected span {
      font-weight: bold !important;
    }
  }

  &__MenuItem {
    margin: 0 !important;
    background-color: transparent !important;

    :after {
      border: 2px solid;
      border-image: linear-gradient(90deg, #A5CD39 0%, #0CACDA 100%) 2 !important;
      height: 50%;
      top: 10px !important;
    }

    span {
      color: #868686;
      margin-left: 10px;
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .TrackerSidebar {
    &__GroupItem {

      &>div,
      ul li span {
        display: none;
      }

      .ant-menu-submenu-title {
        padding-left: 23px !important;
      }
    }
  }
}